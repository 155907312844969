<template>
    <div class="pay" id="agentTitle">
        <Imgbox v-if="!checking"  class="topheader" src="//cdn.zhiyileiju.cn/WechatIMG123.jpeg" />
        <Card style="margin-top:-5px" class="paybox img">
			<div class="testtopcentent">
				<div class="center tophedae">一次购买立即查看所有内容</div>
				<div  v-if="!checking" >
					<p style="text-align: center;color: #525252;">{{xm}}测名结果</p>
					<img style="width: 100%;" src="//cdn.zhiyileiju.cn/WX20210907-102219%402x.jpeg" >
				</div>
			</div>
			<div class="pricebox">
				<div class="disflex">
					<div class="realprice" >限时优惠：￥{{ price.measure_amount }}元</div>
					<span>距优惠结束</span>
				</div>
				<div class="disflex" style="margin-top:8px">
					<span class="discountprice">原价￥{{ price.fake_amount }}</span>
					<van-count-down millisecond color="#fd0101" :time="time" format="HH:mm:ss:SS" />
				</div>
			</div>
            <!-- <van-button type="primary" size="large" color="#04C967" style="margin-bottom: 14px;font-size:22px" icon="//cdn.zhiyileiju.cn/wechaticon111.png" round block @click="getpayurl('wechat','v1_testnamepay_wechat')">微信支付</van-button>
            <van-button type="primary" size="large" color="#1B95D4" style="font-size:22px" icon="//cdn.zhiyileiju.cn/alipayicon111.png" round block @click="getpayurl('alipay','v1_testnamepay_alipay')">支付宝</van-button> -->
            <van-button type="primary" size="large" color="#04C967" style="margin-bottom: 14px;font-size:22px" icon="//cdn.zhiyileiju.cn/wechaticon111.png" round block @click="getpayurl('wechat','v1_testnamepay_wechat')">微信支付</van-button>
            <van-button type="primary" size="large" color="#1B95D4" style="font-size:22px" icon="//cdn.zhiyileiju.cn/alipayicon111.png" round block v-if="!is_weixn" @click="getpayurl('alipay','v1_testnamepay_alipay')">支付宝</van-button>
            <p class="intip center">
                已有<span style="color: #fd0101;font-weight:bold">9,316,618</span>人查看，98.7%认为对人生发展有帮助
            </p>
            <img class="center" style="width: 100%" src="//cdn.zhiyileiju.cn/baozhang.png" alt="" />
        </Card>
        <h2  v-if="!checking" class="center tiph2">支付后你将看到以下所有信息</h2>
		
		<Card  v-if="!checking"  class="img userCard">
			<div class="disflex" style="justify-content: flex-start;">
				<div class="center namebox11" >
					<div v-for="(item,index) in orderinfo.hanzimean.xm" class="WeinameLl_Con1">{{item}}</div>
				</div>
				<div calss="birthdaybox disflex" style="font-size: 12px;">
					<div >性别：{{ orderinfo.sex }}</div>
					<div class="bir" >农历/阴历：{{ orderinfo.nongli.gzYear }}年{{
                        orderinfo.nongli.monthCn + orderinfo.nongli.dayCn
                        }}</div>
				</div>
			</div>
			<div class="cardtit" >
				三才五格打分
			</div>
			<dl>
				<dd class="lh2e wgbox">
					<ul class="wg-list">
						<li>
							<div class="wg-unit">
								外格<span class="wg-num">?</span>
							</div>
						</li>
						<li scwg-name="1">
							<div v-for="(value, index) in xm" :key="value" class="wg-unit">
								<strong>{{ value }}</strong><span class="wg-num">{{orderinfo.hanzimean.kxbihua.split("#")[index]? orderinfo.hanzimean.kxbihua.split("#")[index]:'？'}}</span>
							</div>
						</li>
						<li>
							<div class="wg-unit">
								天格<span class="wg-num">?</span>
							</div>
							<div class="wg-unit">
								人格<span class="wg-num">?</span>
							</div>
							<div class="wg-unit">
								地格<span class="wg-num">?</span>
							</div>
						</li>
					</ul>
				</dd>
			</dl>
			<div class="center">
				总格<span class="wg-num" style="display: inline-block;">?</span>
			</div>
		</Card>
		
		
        <Card  v-if="!checking" class="img userCard">
           <div class="cardtit" >
           八字命盘打分(示例)
           </div>
            <table class="table" border="0" cellspacing="0">
                <tbody>
                    <tr>
                        <th style="width: 24%">示例</th>
                        <th style="width: 19%">年柱</th>
                        <th style="width: 19%">月柱</th>
                        <th style="width: 19%">日柱</th>
                        <th style="width: 19%">时柱</th>
                    </tr>
                    <tr >
                        <td>出生时间</td>
                        <td k="year_zhu">{{ orderinfo.eightChara.bazi[0].y }}</td>
                        <td k="month_zhu">{{ orderinfo.eightChara.bazi[1].m }}</td>
                        <td k="day_zhu">{{ orderinfo.eightChara.bazi[2].d }}</td>
                        <td k="hour_zhu">{{ orderinfo.eightChara.bazi[3].h }}</td>
                    </tr>
                    <tr>
                        <td>纳音</td>
                        <td k="year_ly">{{ orderinfo.eightChara.nayin[0].y }}</td>
                        <td k="month_ly">{{ orderinfo.eightChara.nayin[1].m }}</td>
                        <td k="day_ly">{{ orderinfo.eightChara.nayin[2].d }}</td>
                        <td k="hour_ly">{{ orderinfo.eightChara.nayin[3].h }}</td>
                    </tr>
                    <tr>
                        <td>喜用分析</td>
                        <td colspan="4">
                            <div class="pifen disflex" wx="木">
                                <span>木</span>
                                <div class="progress">
                                    <span class="back_jin" :style="'width:' + orderinfo.askTao.qk_score_ratio['木']"></span>
                                </div>
                                <span class="fs">{{
                                    orderinfo.askTao.qk_score_ratio["木"]
                                    }}</span>
                            </div>
                            <div class="pifen disflex" wx="火">
                                <span>火</span>
                                <div class="progress">
                                    <span class="back_mu" :style="'width:' + orderinfo.askTao.qk_score_ratio['火']"></span>
                                </div>
                                <span class="fs">{{ orderinfo.askTao.qk_score_ratio["火"] }}
                                </span>
                            </div>
                            <div class="pifen disflex" wx="土">
                                <span>土</span>
                                <div class="progress">
                                    <span class="back_shui" :style="'width:' + orderinfo.askTao.qk_score_ratio['土']"></span>
                                </div>
                                <span class="fs">{{
                                    orderinfo.askTao.qk_score_ratio["土"]
                                    }}</span>
                            </div>
                            <div class="pifen disflex" wx="金">
                                <span>金</span>
                                <div class="progress">
                                    <span class="back_huo" :style="'width:' + orderinfo.askTao.qk_score_ratio['金']"></span>
                                </div>
                                <span class="fs">{{
                                    orderinfo.askTao.qk_score_ratio["金"]
                                    }}</span>
                            </div>
                            <div class="pifen disflex" wx="水">
                                <span>水</span>
                                <div class="progress">
                                    <span class="back_tu" :style="'width:' + orderinfo.askTao.qk_score_ratio['金']"></span>
                                </div>
                                <span class="fs">{{
                                    orderinfo.askTao.qk_score_ratio["水"]
                                    }}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Card>
        <Card  v-if="!checking" class="img userCard">
            <div class="title2 disflex">您的姓名分数</div>
            <div class="infoImg">
                <div class="info">
                    <div class="infoText shadow">
                        <p>1、分析名字与您生辰八字的相合度</p>
                        <p>2、分析名字的三才五格</p>
                        <p>3、得到名字的综合评分</p>
                    </div>
                </div>
            </div>
			<van-button class="getbutton" size="large" color="#B95353" type="info" @click="pay('v1_testnamepay_content_btn')">
			   <!-- <template #icon>
			        <img style="width:15px;margin-right:8px" src="https://cdn.zhiyileiju.cn/lock%20close.png" alt="" />
			    </template> -->
			   支付后查看全部分析</van-button>
        </Card>
        <Card  v-if="!checking" class="img userCard">
            <div class="title2 disflex">您的名字运势</div>
            <div class="infoImg">
                <div class="info">
                    <div class="infoText shadow">
                        <p>1、判断您名字对事业财运影响</p>
                        <p>2、判断您名字对恋爱婚姻影响</p>
                        <p>3、判断您名字对未来运势影响</p>
                    </div>
                </div>
            </div>
			<van-button class="getbutton" size="large" color="#B95353" type="info" @click="pay('v1_testnamepay_content_btn')">
			    <!-- <template #icon>
			        <img style="width:15px;margin-right:8px" src="https://cdn.zhiyileiju.cn/lock%20close.png" alt="" />
			    </template> -->
			    支付后查看全部分析</van-button>
        </Card>
        <Card  v-if="!checking" class="img userCard">
            <div class="title2 disflex">您的五行喜用</div>
            <div class="infoImg">
                <div class="info">
                    <div class="infoText shadow">
                        <p>1、分析您生辰八字五行含量</p>
                        <p>2、分析您最弱和最强的五行</p>
                        <p>3、告诉您增旺哪类五行对运势最好</p>
                    </div>
                </div>
            </div>
			<van-button class="getbutton" size="large" color="#B95353" type="info" native-type="submit" @click="pay('v1_testnamepay_content_btn')">
			   <!-- <template #icon>
			        <img style="width:15px;margin-right:8px" src="https://cdn.zhiyileiju.cn/lock%20close.png" alt="" />
			    </template> -->
			    支付后查看全部分析</van-button>
        </Card>
        <Card  v-if="!checking" class="img userCard">
            <div class="title2 disflex">未来发展建议</div>
            <div class="infoImg">
                <div class="info">
                    <div class="infoText shadow">
                        <p>1、分析您生辰八字的命格特征</p>
                        <p>2、告诉您该命格下未来发展运势</p>
                        <p>3、告诉您一些未来发展建议</p>
                    </div>
                </div>
            </div>
			<van-button class="getbutton" size="large" color="#B95353" type="info" native-type="submit" @click="pay('v1_testnamepay_content_btn')">
			   <!-- <template #icon>
			        <img style="width:15px;margin-right:8px" src="https://cdn.zhiyileiju.cn/lock%20close.png" alt="" />
			    </template> -->
			    支付后查看全部分析</van-button>
        </Card>
        <Card class="img">
            <Comments  v-if="!checking" type="testname" />
            <div   v-if="!checking" class="title2 disflex">最新支付订单</div>
            <orderscroll  v-if="!checking" type="testname" />
            <div class="title2 disflex mt-12">常见问题</div>
            <van-collapse v-model="activeNames" :accordion="true">
                <van-collapse-item :is-link="false" title="1、你们是专业正规的起名公司吗？如何相信？" name="1">因为是在网上，我可以理解你的心情，但是我们公司的信誉，您绝对放心。首先我们是正规成立的公司，是具有工商认证经营的。其次我们网站是开通了快手推广、百度推广、搜狗推广、360推广，都是经过这些平台的全面审核才上线的，您可以放心体验。
                </van-collapse-item>
                <van-collapse-item :is-link="false" title="2、付款以后如何获取姓名测试结果？ " name="2">付款以后会自动跳转到姓名测试结果页面，查看测试结果非常方便快捷。</van-collapse-item>
                <van-collapse-item :is-link="false" title="3、八字的五行，是缺什么就补什么吗？" name="3">不是。需要区别对待，精确分析，真正做到“需要什么，则补什么”。否则就是简单加减，补救容易失误。
                </van-collapse-item>
                <van-collapse-item :is-link="false" title="4、测试了名字不满意怎么办" name="4">诸姓名测试的不满意率只有千分之二，原因大多是因为操作不熟练、需求不明确、追求满分体验造成的。 如果您有疑问请及时联系客服，我们一定能完美解决您所提出的问题，争取满意百分百。
                </van-collapse-item>
            </van-collapse>
			<div class="center">
				<img width="100%" src="//cdn.zhiyileiju.cn/WechatIMG144.jpeg" >
			</div>
		<!-- 	<div class="center" style="font-size: 24px;font-weight: bold;color:#C94B4B;margin-top:30px;">专业 · 高标准 · 值得信赖</div>
			<div class="center" style="padding-bottom: 60px;">
				<img style="width: 220px;margin-top: 15px;" src="../assets/WechatIMG169.png" >
			</div> -->
        </Card>

        <van-button type="primary" class="fixbtn"  :class="fixedbtn == true ? 'isFixed' : ''"  color="#B95353" block @click="pay('v1_testnamepay_bottom_btn')">支付后查看测名结果</van-button>
		
        <van-overlay :show="shopPayConfirm" z-index="10" lock-scroll>
            <div class="wrapper center" @click.stop>
                <div class="block">
                    <div class="title">请确认支付是否完成</div>
                    <div class="payed">
                        <van-button round block @click="Dcheckorder" class="Dcheckorder" type="primary">付款成功，查看测名结果</van-button>
                    </div>
                    <div class="unpay" @click="unpay">
                        未支付，我再考虑一下
                    </div>
                </div>
            </div>
        </van-overlay>
        <div class="center" style="margin: 10px 0;">客服联系微信：sunwei13844768688</div>
			
		<div v-if="ifFqudao"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有 ©2018-2021 武汉道森梯媒文化传播有限公司</div>
			<div>地址：武汉市武昌区徐东大街128号</div>
			<div>电话：18975441215</div>
		</div>
      <!-- <div  v-if="isbjks"  class="webfooter" style="height:180px;font-size:10px;color:#323232">
			<div>版权所有©2018-2021上海容铭信息科技有限公司</div>
			<div>公司地址：上海市杨浦区抚顺路360号</div>
			<div>电话：18975441215</div>
		</div>
		<div v-else-if="isks4"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有 ©2018-2021 武汉道森梯媒文化传播有限公司</div>
			<div>地址：武汉市武昌区徐东大街128号</div>
			<div>电话：18975441215</div>
		</div>

       	<div v-else-if="ifFqudao"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有 ©2018-2021 苏州特诺维信息技术有限公司</div>
			<div>地址：苏州工业园区星湖街328号创业产业园A1-16</div>
			<div>电话：15695165587</div>
		</div>
		<div v-else  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有 ©2018-2021 湖南亮亮文化传媒有限公司</div>
			<div>地址：长沙市开福区月湖街道鸭子铺路1号1房2室</div>
			<div>电话：18975441215</div>
		</div> -->
        <!-- <div class="webfooter"  style="height:120px;background:#fdf9ea;"></div> -->
    </div>
</template>
<script>
import Comments from "../components/Comment.vue";
import vueSeamlessScroll from "vue-seamless-scroll";
import orderscroll from "../components/OrderScroll.vue";
import axios from "../utils/request";
import solarLunar from "../components/datepick/solarlunar";
import { CountDown } from "vant";

import { Overlay } from "vant";
import { Toast } from "vant";

import { Dialog } from "vant";

import router from "../router";

import report from "../utils/stat";



export default {
    name: "Pay",
    components: {
        Comments,
        vueSeamlessScroll,
        vanCountDown: CountDown,
        vanOverlay: Overlay,
        vanToast: Toast,
        orderscroll
    },
    async mounted() {
        if (this.is_weixn) {
				let wxconfig = await this.getWxConfig();
				wx.config({
					debug: false,
					appId: wxconfig.appId,
					timestamp: wxconfig.timestamp,
					nonceStr: wxconfig.nonceStr,
					signature: wxconfig.signature,
					jsApiList: ['chooseWXPay']
				});
			}

        document
            .getElementById("agentTitle")
            .scrollIntoView({ block: "start", behavior: "smooth" });
			document.title = "诸葛国学测名";
    },
    data() {
        return {
            listData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            orderlist: [],
            activeNames: ["1"],
            showPayDialog: false,
            showfixPayBtn: true,
            shopPayConfirm: false,
            fixedbtn: false,
            orderinfo: {
                bDate: [2021],
                nongli: {},
                eightChara: {
                    bazi: [{ y: "辛丑" }, { m: "乙未" }, { d: "甲子" }, { h: "甲子" }],
                    nayin: [
                        { y: "壁上土" },
                        { m: "沙中金" },
                        { d: "海中金" },
                        { h: "海中金" },
                    ],
                },
                askTao: {
                    qk_score_ratio: {
                        金: "6%",
                        木: "11%",
                        水: "25%",
                        火: "12%",
                        土: "46%",
                    },
                },
				scwg:{
					"fiveGirl":
					{"天":16,"地":10,"人":24,"总":24,"外":2},
				},
				hanzimean:{xm: "李晓丽", ft: "李曉丽", pinyin: "lǐ#xiǎo#lì#", kxbihua: "7#16#19#", wx: "火火火"}
            },
            time: 1000 * 60 * 30,
            price: {
                base_level: null,
                created_at: null,
                fake_amount: "198.00",
                fast_amount: null,
                id: 1,
                true_amount: "",
                type: 1,
                updated_at: null,
            },
            orderfakedata: [{
                    name: "王*慧",
                    time: "12分钟前",
                },
                {
                    name: "李*",
                    time: "7分钟前",
                },
                {
                    name: "孙*飞",
                    time: "3分钟前",
                },
                {
                    name: "刘*云",
                    time: "5分钟前",
                },
                {
                    name: "赵*",
                    time: "1分钟前",
                },
                {
                    name: "周**",
                    time: "3分钟前",
                },
                {
                    name: "李*惠",
                    time: "3分钟前",
                },
                {
                    name: "古*歌",
                    time: "5分钟前",
                },
            ],
        };
    },
    destroyed() {
        this.timers && clearTimeout(this.timers);
    },
	beforeDestroy(){
		 this.timers && clearTimeout(this.timers);
	},
    async created() {
		report("v1_testnamepay");
        let paytype = this.$route.params.type;
        this.orderid = this.$route.params.id;
        this.type = this.$route.params.type;
        if (paytype != 10) {
            try {
                await this.mcheckorder();
            } catch (err) {}
            this.shopPayConfirm = true;
        } else {}
        this.getorderinfo(this.$route.params.id);
        this.getFakeOrder();
        await this.getprice();
        this.showToast();
		window.addEventListener("scroll", this.handleScroll);
    },

    computed: {
        is_weixn() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
        defaultOption() {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: this.listData.length,
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: false, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
                openTouch: false,
            };
        },
		xm(){
			return this.orderinfo.xname+this.orderinfo.name
		},
        dialogwidth() {
            return window.innerWidth * 0.9;
        },
       checking(){
			let from = this.$route.query.from;
			let allowfrom = ['ft3','tk1','tk2','tk3','tk4','d1','d2','a10'];
			if (allowfrom.indexOf(from) > -1) {
				return true
			} else {
				return false
			}
       },
	   ifFqudao(){
			let from = this.$route.query.from;
				let allowfrom = ['ft1', 'ft2',];
			if (allowfrom.indexOf(from) > -1) {
				return true
			} else {
				return false
			}
	   },
	   ifvo(){
			let from = this.$route.query.from;
			let allowfrom = ['tk1','tk2','tk3','tk4'];
			if (allowfrom.indexOf(from) > -1) {
				return true
			} else {
				return false
			}
	   },
       
    },
    methods: {
        pay(action) {
			this.hasclick = true;
			document
			    .getElementById("agentTitle")
			    .scrollIntoView({ block: "start", behavior: "smooth" });
            report(action);
        },
        unpay(){
            router.back();
        },
		handleScroll() {
			var scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (scrollTop > 300) {
				this.fixedbtn = true;
			} else {
				this.fixedbtn = false;
			}
		},
        async getFakeOrder() {
            let info = await axios.post("/ekaf", {
                type: 2,
                perPage: 15,
                page: 1,
                fromk: this.$route.query.from
            });
            this.orderlist = info.data.data;
        },

        async getWxConfig() {
				let url = window.location.href;

				let data = await axios.post("/payment/wechatconf", {
					url
				})
				return data.data.data.data;
			},

        showToast() {
            let t = this;
            let arr = this.orderfakedata;
            let data = arr[Math.floor(Math.random() * arr.length)];
            Toast({
                message: `${data.time} ${data.name}支付了${this.price.measure_amount}元测试姓名`,
                position: "bottom",
                duration: 2000,
            });
            this.timers = setTimeout(function() {
                t.showToast();
            }, 5000);
        },

        async timeout(time) {
            return new Promise((sol, ret) => {
                setTimeout(() => {
                    sol()
                }, time);
            })
        },

        async getpayurl(payway, aciton) {
            Toast.loading({
                message: '请稍后...',
                forbidClick: true,
            });
			if(this.hasclick){
				report(aciton+"_content");
			}else{
				report(aciton);
			}
   
            let orderid = this.orderid;
            let type = payway == "alipay" ? 1 : 2;
            this.type = type;
            let orderinfo = await axios.get("/order/" + orderid);
            if (
                orderinfo.data.data.order_status == "已完成" ||
                orderinfo.data.data.order_status == "已支付"
            ) {
                Dialog.confirm({
                    message: "当前订单已支付",
                    showCancelButton: false,
                }).then(() => {
                    router.replace({ name: "aitestsuccess", params: { id: orderid, type } }).catch(() => {});
                });
                return;
            }
            let t;
            setTimeout(async function() {
                await t.checkorder(orderid, type);
            }, 2000);
            try {
                await this.confirmOrder(orderid, type);
                Toast.clear()
                // location.href = `https://api.zhiyileiju.cn/api/v1/payment/${payway}/${orderid}`;
                this.shopPayConfirm = true;

                if (this.is_weixn) {
						let payinfo = await axios.get(
							`https://api.zhiyileiju.cn/api/v1/payment/${payway}/${orderid}/wechat`)
						let pay = payinfo.data.data;

						wx.ready(function() {
							wx.chooseWXPay({
								timestamp: pay.timeStamp,
								nonceStr: pay.nonceStr,
								package: pay.package,
								signType: pay.signType,
								paySign: pay.paySign, // 支付签名
								success: function(res) {
									router.replace({
                                        name: "aitestsuccess",
                                        params: { id, type:1 },
                                    }).catch(() => {});
								}
							});
						});


					} else {
						location.href = `https://api.zhiyileiju.cn/api/v1/payment/${payway}/${orderid}`;
					}
            } catch (err) {}
        },
        async getprice() {
			 let from = this.$route.query.from || 10;
            let price = await axios.get(`/getSalesPrice?fromk=${from}`);
          
            this.price = price.data.data[0];
        },
        async getorderinfo(id) {
            let data = await axios.get("/order/" + id);
            this.orderinfo = data.data.data;
            // if (
            //     this.orderinfo.order_status == "已支付" ||
            //     this.orderinfo.order_status == "已完成"
            // ) {
            //     Dialog.confirm({
            //         message: "当前订单已支付",
            //         showCancelButton: false,
            //     }).then(() => {
            //         router.replace({ name: "paysuccess", params: { id, type: 1 } });
            //     });
            // } else if (this.orderinfo.order_status == "已关闭") {
            //     Dialog.confirm({
            //         message: "当前订单已关闭",
            //         showCancelButton: false,
            //     }).then(() => {
            //         router.replace({ name: "Index" }).catch(() => {}); // on confirm
            //     });
            // }
            let birth = data.data.data.pub_birth_date ?
                data.data.data.pub_birth_date :
                data.data.data.pub_prebirth_date;
            let fdate = birth.split(" ");
            this.orderinfo.bDate = [...fdate[0].split("-"), ...fdate[1].split(":")];
            this.orderinfo.nongli = solarLunar.solar2lunar(
                this.orderinfo.bDate[0],
                this.orderinfo.bDate[1],
                this.orderinfo.bDate[2]
            );
            let nowtime = Date.now();
            let cDate = data.data.data.created_at.replace(" ", "T");
            let createtime = new Date(cDate).getTime();
            this.time = 10 * 60 * 1000 - nowtime + createtime;
        },
        checkorder(id, type) {
            this.timer = setInterval(async () => {
                try {
                    let data = await axios.post("/payment/query", {
                        paytype: type,
                        order_id: id,
                    });
                    if (data.data.data.is_pay) {
                        router.replace({
                            name: "aitestsuccess",
                            params: { id, type: data.data.data.type },
                        }).catch(() => {});
                    }
                } catch (err) {}
            }, 5000);
        },
        async mcheckorder() {
            let data = await axios.post("/payment/query", {
                paytype: this.type,
                order_id: this.orderid,
            });
            if (data.data.data.is_pay) {
                router.replace({
                    name: "aitestsuccess",
                    params: { id: this.orderid, type: data.data.data.paytype },
                }).catch(() => {});
            } else {
                Toast.fail("当前订单未支付");
            }
        },
        async Dcheckorder() {
            try {
                this.mcheckorder();

            } catch (err) {
                Toast.fail("当前订单未支付");
            }
        },
        async confirmOrder(id, type) {
            try {
                let aa = await axios.post("/order/confirm", {
                    order_id: id,
                    paytype: type,
                });
            } catch (err) {}
        },
        bottombtnclick() {
            window.pageYOffset = 0;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
        },
        handleScroll() {
            var scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (scrollTop > 300) {
                this.fixedbtn = true;
            } else {
                this.fixedbtn = false;
            }
        },
    },
};
</script>
<style scoped>
.wrapper {
    width: 100vw;
    height: 100vh;
    max-width: 640px;
    margin: 0 auto;
}

.block {
    width: 80%;
    background-color: white;
    border-radius: 5px;
}

.Dcheckorder {
    width: 85%;
    margin: 10px auto;
    animation: scaleDrew 1.5s ease-in-out infinite;
    font-size: 18px;
}

.block .title {
    line-height: 44px;
    text-align: center;
    font-size: 15px;
    border-bottom: 1px solid #eeeeee;
}

.block .payed {
    color: #b91e1e;
    font-size: 18px;
    line-height: 80px;
    text-align: center;
    border-bottom: 1px solid #eeeeee;
}

.unpay {
    color: #999;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
}

.dialogbox {
    padding-bottom: 25px;
}

.dialogheader {
    height: 70px;
    background: #d6d5d5;

    font-size: 18pxpx;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #525252;
}

.closeicon {
    width: 30px;
    position: absolute;
    right: 18px;
}

.content {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #525252;
}
</style>
<style scoped>
.webfooter {
    /* height: 120px; */
    /* background: url("//cdn.zhiyileiju.cn/kexin.png") no-repeat top;
  background-size: 250px;
  background-color: #fff; */

    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    line-height: 2em;
    font-size: 14px;
}
dd{
    padding: 0;
    margin: 0;
}

.dialogrealprice {
    text-decoration: line-through;
    font-size: 12px;
    color: #979797;
    margin-right: 5px;
}

.pricewarp {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.tehuiprice {
    color: #fd0101;
    font-size: 14px;
    font-weight: Regular;
    margin-right: 5px;
}

.dialogdisp {
    font-size: 28px;
    font-weight: bold;
    font-family: Microsoft YaHei;
    color: #fd0101;
    line-height: 1em;
}

.red {
    color: #fd0101;
}

.green {
    color: #19aa0f;
}

.paybtnbox {
    width: 80vw;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.isFixed {
    position: fixed;
    font-size: 18px;
    height: 60px;
    bottom: 0;
    z-index: 999;
    max-width: 640px;
    animation: scaleDrew 1.5s ease-in-out infinite;
}
</style>
<style scoped>
.header {
    width: 100vw;
    background: #b95353;
    height: 31px;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.topheader {
    min-height: 190px;
}

.paybox {
    padding: 14px 20px;
}

.paybox .usertip {
    text-align: left;
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #020202;
}

.userinforow {
    font-size: 14px;
    font-weight: 400;
    color: #020202;
    margin-top: 10px;
}

.paytip {
    font-size: 12px;
    font-weight: 400;
    color: #b95353;
}

.paytip.h1 {
    font-size: 26px;
    margin-top: 14px;
    margin-bottom: 14px;
    font-weight: bold;
    color: #fd0101;
}

.priceinfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 76vw;
    height: 116px;
    border: 1px solid #aaa79b;
    background: #f2eedf;
}

.paytime {
    display: flex;
    margin-top: 8px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #fd0101;
    align-self: flex-start;
}

.van-count-down {
    color: #fd0101;
}

.pricebox {
    margin-top: 10px;
    margin-bottom: 20px;
}

.realprice {
    font-size: 21px;
    font-weight: 500;
    font-family: Microsoft YaHei;
    color: #fd0101;
    line-height: 1em;
}

.jlyh{
    font-size: 12px;
    font-weight: 500;
    color: #323232;
}

.discountprice {
    font-size: 12px;
    font-weight: 400;
    color: #979797;
    text-decoration: line-through;
}


.pricefooter {
    width: 100%;
    height: 32px;
    background: white;
    font-size: 12px;
    font-weight: 400;
    color: #020202;
}

.img {
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 15px;
    margin-top: 15px;
}

.intip {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #020202;
}

.van-icon img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.tiph2 {
    font-size: 16px;
    font-weight: bold;
    color: #C94B4B;
}
.WeinameLl_Con1{
    width: 40px;
    height: 40px;
    background: url(//cdn.names.org.cn/website/Content/szname/img/NewNameBjk.png) no-repeat;
    background-size: 100%;
    text-align: center;
    line-height: 40px;
    font-size: 24px;
    font-weight: 500;
    margin-right: 7px;
}

.cardtit{
    font-size: 16px;
    font-weight: 500;
    margin:14px 0;
    color: #525252;
}
.birthdaybox{
    height: 40px;
}

.birthdaybox .bir{
    flex-shrink: 2;
    font-size: 12px;
    color: #666;
}
</style>
<style lang="less" scoped>
	@siteColor: #b95353;
	.wgbox {
		padding: 15px;
		text-align: center;
	
		li {
			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;
			border-left: 1px solid @siteColor;
		}
	
		.wg-unit {
			margin: 10px 0;
	
			&::before {
				display: inline-block;
				vertical-align: middle;
				width: 15px;
				height: 1px;
				content: "";
				background-color: @siteColor;
			}
		}
	
		.wg-num {
			display: inline-block;
			vertical-align: middle;
			margin-left: 5px;
			width: 25px;
			height: 25px;
			line-height: 25px;
			color: #b95353;
			background-color: @siteColor;
			color: #fff;
		}
	
		h4 {
			margin-top: 25px;
			font-weight: bold;
			text-align: center;
		}
	}
    .wg-num {
        display: inline-block;
        text-align: center;
        margin-left: 5px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        color: #b95353;
        background-color: @siteColor;
        color: #fff;
	}
</style>
<style scoped>
.infoImg {
    position: relative;
    display: block;
    background: url("//cdn.zhiyileiju.cn/z_wap_12.jpeg") 0 0 no-repeat;
    background-size: 100% 100%;
}

.infoText {
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
}

.infoText p {
    margin: 5px 0;
}

.shadow {
    box-shadow: 0 0 8px #ddd;
    background-color: white;
    margin-bottom: 12px;
    border-radius: 6px;
}

.infoImg .info {
    width: 80%;
    margin: 0 auto 10px auto;
    padding: 12% 0;
}

.mt-12 {
    margin-top: 16px;
}

.getbutton {
    border-radius: 6px;
    margin-top: 10px;
	font-size: 20px;
    background: #c94b4b;
}
</style>
<style scoped>
.userCard {
    padding: 14px 19px;
}

.userBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.xingshi {
    width: 60px;
    height: 60px;
    background: url("//cdn.zhiyileiju.cn/NewNameBjk.png") 0 0 no-repeat;
    background-size: 100% 100%;
    line-height: 60px;
    text-align: center;
    font-size: 30px;
    margin-right: 10px;
    border: 1px solid #ddd;
    color: #b95353;
}

.userBox p {
    color: #666;
    margin: 7px;
    font-size: 14px;
    font-weight: bold;
    color: #525252;
}

.table {
    width: 100%;
    border: 1px solid #ddd;
    margin: 20px 0;
    font-size: 14px;
}

.table td,
.table th {
    text-align: center;
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #eddbcd;
    border-right: 1px solid #eddbcd;
}

.table th,
.table td:first-child {
    background: #f2eedf;
}

.table th:first-child {
    background: #fff;
}

.table td:last-child,
.table th:last-child {
    border-right: 0;
}

.pifen {
    width: 90%;
    padding: 0 10px;
    padding-right: 5px;
}

.pifen .mc {
    width: 20px;
    height: 20px;
}

.back_jin {
    background: #fe0;
}

.back_mu {
    background: #0aa90a;
}

.back_shui {
    background: #b5ddec;
}

.back_huo {
    background: #ef3e33;
}

.back_tu {
    background: #92551c;
}

.pifen .progress {
    width: 60%;
    top: 0;
    margin: 0 5px;
}

.pifen .progress span {
    animation: animate-positive 2s;
    transition: width 0.6s ease;
}

.pifen span.fs {
    /* margin: 0 5px; */
    color: #c33936;
    font-size: 14px;
}

.progress,
.progress span {
    width: 100%;
    height: 12px;
    border-radius: 12px;
    display: inline-block;
    text-align: left;
}

.progress {
    background: #f1eae6;
    position: relative;
}

.progress span {
    position: absolute;
}

.disflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title2 {
    font-size: 18px;
    font-weight: bold;
    color: #b95353;
    justify-content: center;
    margin-bottom: 14px;
}

.testtopcentent{ border: 1px solid rgba(145, 117, 108, 0.38); border-radius: 5px;}

.testtopcentent .tophedae{font-size: 21px; height: 44px;background: #FFF7EC;line-height: 44px;width: 100%; color: red;border-radius: 5px 5px 0 0; border-bottom: 1px solid rgba(145, 117, 108, 0.38); font-weight: 500;
}
	
</style>